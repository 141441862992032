// Make polyglot available on window for our older, browser JS

import t, { tList, withLocalePrefix } from 'shared/utils/translation';

if (window && window.Greenhouse) {
  window.Greenhouse.Translation = window.Greenhouse.Translation || {};
  window.Greenhouse.Translation.t = t;
  window.Greenhouse.Translation.tList = tList;
  window.Greenhouse.Translation.withLocalePrefix = withLocalePrefix;
}
